import { produce } from 'immer';
import actions from '../../Checkout/Quote/actions';

const initialState = {
    quote: [],
    limitations: [],
    hasLimitations: false,
    checkingLimitations: true,
    loaders: {
      retrievingQuote: false,
	  updatingQuote: false,
	  checkingStock: false,
	  quoteInit: true,
	  gettingAlternativeProducts: false,
    },
    sessionSaved: null,
    quoteSaved: false,
    quoteUpdated: false,
	alternativePercentLoader: 0,
	alternativeCount: 0,
    outOfStock: 0,
	checkedStock: false,
    finishedStockChecks: 0,
    readyForStockCheck: false,
    passedQuoteStage: false,
    navigateToQuote: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {

    case actions.CHECKOUT_QUOTE_SET_QUOTE:
		return {
			...state, 
			quote: {
				...state.quote,
				...action.payload
			}
    }
    case actions.UPDATE_QUOTE_OBJECT_KEY:
		return {
			...state, 
			quote: {
				...state.quote,
				[action.payload.key]: action.payload.value
			}
    }
	case actions.CHECKOUT_QUOTE_SET_LOADER:
		return produce(state, (draft) => {
			draft.loaders[action.payload.key] = action.payload.value;
		})

	case actions.CHECKOUT_QUOTE_SET_KEY:
		return produce(state, (draft) => {
			draft[action.payload.key] = action.payload.value;
		})

    case actions.CHECKOUT_QUOTE_RESET:
      	return initialState;
    default:
      	return state
  }
}

export default Reducer;
