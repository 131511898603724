import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import actions from '../Delivery/actions';

import Bugsnag from '@bugsnag/js';
import { getRequest } from '@components/config/axiosClient';

export const getQuote = (state) => state.checkoutQuoteReducer.quote;

function* getInstallDerig(action) {
  const quote = yield select(getQuote);
  try {
    const response = yield call(() => getRequest('quote/transport?brand=' + process.env.brand.brand_key + '&delivery-postcode=' + quote.deliveryPostcode + '&collection-postcode=' + quote.collectionPostcode + '&cart-total-weight=' + quote.cartTotalWeight)); 
    yield put({type: actions.DELIVERY_GET_INSTALL_DERIG_SUCCESS, payload: response.data});
  } catch (error) {
    Bugsnag.notify(error);
    yield put({type: actions.DELIVERY_GET_INSTALL_DERIG_FAILURE, payload: error});
  }
}

function* getSurcharges(action) {
  const quote = yield select(getQuote); //grab the current state of the quote
  //send an api req to get the current pricing of surcharges and store them in the state
  try {
    const response = yield call(() => getRequest('surcharges?brand=' + process.env.brand.brand_key + '&delivery-postcode=' + quote.deliveryPostcode + '&collection-postcode=' + quote.collectionPostcode)); 
    yield put({type: actions.DELIVERY_GET_SURCHARGES_SUCCESS, payload: response.data});
  } catch (error) {
    Bugsnag.notify(error);
    yield put({type: actions.DELIVERY_GET_SURCHARGES_FAILURE, payload: error});
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.DELIVERY_GET_SURCHARGES, getSurcharges)]);
  yield all([takeLatest(actions.DELIVERY_GET_INSTALL_DERIG, getInstallDerig)]);
}
